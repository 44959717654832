export const GET_REDIRECT_BUYER_REQUEST = "GET_REDIRECT_BUYER_REQUEST";
export const GET_REDIRECT_BUYER_SUCCESS = "GET_REDIRECT_BUYER_SUCCESS";
export const GET_REDIRECT_BUYER_FAIL = "GET_REDIRECT_BUYER_FAIL";

export const CREATE_REDIRECT_BUYER_REQUEST = "CREATE_REDIRECT_BUYER_REQUEST";
export const CREATE_REDIRECT_BUYER_SUCCESS = "CREATE_REDIRECT_BUYER_SUCCESS";
export const CREATE_REDIRECT_BUYER_FAIL = "CREATE_REDIRECT_BUYER_FAIL";

export const UPDATE_REDIRECT_BUYER_REQUEST = "UPDATE_REDIRECT_BUYER_REQUEST";
export const UPDATE_REDIRECT_BUYER_SUCCESS = "UPDATE_REDIRECT_BUYER_SUCCESS";
export const UPDATE_REDIRECT_BUYER_FAIL = "UPDATE_REDIRECT_BUYER_FAIL";

export const DELETE_REDIRECT_BUYER_REQUEST = "DELETE_REDIRECT_BUYER_REQUEST";
export const DELETE_REDIRECT_BUYER_SUCCESS = "DELETE_REDIRECT_BUYER_SUCCESS";
export const DELETE_REDIRECT_BUYER_FAIL = "DELETE_REDIRECT_BUYER_FAIL";