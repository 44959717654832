export const GET_REDIRECT_DESTINATION_REQUEST = "GET_REDIRECT_DESTINATION_REQUEST";
export const GET_REDIRECT_DESTINATION_SUCCESS = "GET_REDIRECT_DESTINATION_SUCCESS";
export const GET_REDIRECT_DESTINATION_FAIL = "GET_REDIRECT_DESTINATION_FAIL";

export const GET_USER_REDIRECT_GROUPS_REQUEST = "GET_USER_REDIRECT_GROUPS_REQUEST";
export const GET_USER_REDIRECT_GROUPS_SUCCESS = "GET_USER_REDIRECT_DESTINATION_SUCCESS";
export const GET_USER_REDIRECT_GROUPS_FAIL = "GET_USER_REDIRECT_DESTINATION_FAIL";

export const UPDATE_REDIRECT_DESTINATION_REQUEST = "UPDATE_REDIRECT_DESTINATION_REQUEST";
export const UPDATE_REDIRECT_DESTINATION_SUCCESS = "UPDATE_REDIRECT_DESTINATION_SUCCESS";
export const UPDATE_REDIRECT_DESTINATION_FAIL = "UPDATE_REDIRECT_DESTINATION_FAIL";